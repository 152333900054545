import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import yonghe0 from '../assets/images/trig_001.webp'
import yonghe1 from '../assets/images/trig_002.webp'
import yonghe2 from '../assets/images/trig_003.webp'
import yonghe3 from '../assets/images/trig_004.webp'
import yonghe4 from '../assets/images/trig_005.webp'
import yonghe5 from '../assets/images/trig_006.webp'
import yonghe6 from '../assets/images/trig_007.webp'
import yonghe7 from '../assets/images/trig_008.webp'
import yonghe8 from '../assets/images/trig_009.webp'
import yonghe9 from '../assets/images/trig_010.webp'
import yonghe10 from '../assets/images/trig_011.webp'
import yonghe11 from '../assets/images/trig_012.webp'
import yonghe12 from '../assets/images/trig_013.webp'
import yonghe13 from '../assets/images/trig_014.webp'
import price from '../assets/images/price.jpg'

import googleMap from '../assets/images/google-maps.png'

const Yonghe = props => (
  <Layout>
    <Helmet>
      <title>新北永和健身房｜三健客3musclers健身俱樂部</title>
      <meta
        name="description"
        content="三健客新北永和健身房分館，有多元的健身以及體能訓練課程，場館內也有專業品牌ROGUE、ELEIKO、CYBEX、Hammer的健身品牌設備，推薦不論是重訓、體態雕塑、肌力體能訓練等運動需求，三健客都可以給您完善的健身計劃及課程，帶領您一起達成自己的理體態。"
      />
      <meta
        name="keywords"
        content="三健客,健身房,永和健身房,健身房推薦,健身俱樂部,健身房課程,私人健身房,教練課程,健身房教練,團體課程"
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>三健客永和店</h1>
          </header>
          <span className="image main">
            <img src={yonghe6} alt="3musclers" />
          </span>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={yonghe2} alt="3musclers" />
                </span>
              </div>
              <div className="col-4" s>
                <span className="image main">
                  <img src={yonghe1} alt="3musclers" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={yonghe4} alt="3musclers" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe9} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe8} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe10} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe3} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img
                    src={yonghe0}
                    alt=""
                    style={{ width: '100%', height: '60%' }}
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img
                    src={yonghe11}
                    alt=""
                    style={{ width: '100%', height: '60%' }}
                  />
                </span>
              </div>
            </div>
          </div>
          <h3 style={{ color: '#fcce10' }}>💪 器材</h3>
          <p>
            🏋🏼‍♀️自由重量:
            <br />
            ELEIKO比賽架跟鑄鐵槓片，四台超抗震硬舉台
          </p>
          <p>
            美國ROGUE 深蹲架7台、腰帶深蹲、臥推架三台、槓很多根 各種特殊槓、
            水牛槓 SSB、足球槓、camber bar、一堆槓片、啞鈴架有四座
          </p>
          🏋🏼‍♀️機械式器材:都是精挑細選 （life fitness cybex hammer strength）
          <p>
            🏋🏼‍♀️插銷式器材:
            八站式飛鳥、小飛鳥x2、弧形無動力跑步機、下拉划船單機X2、腿外展內收X2、坐式腿後勾、趴式腿後勾、蝴蝶機夾胸後三角X2、坐式肩推、坐姿腿推、平胸推、坐姿腹肌、二頭、三頭單機側飛挑了三個不同廠牌(LifeFitness、CYBEX、GYM80)
          </p>
          🏋🏼‍♀️分動式外掛器材 上胸推x2下胸推、正拉、反拉、水平拉、腿推、Hack
          squat、史密斯、臀推
          <p>
            🏋🏼‍♀️有氧器材 階梯機X2、跑步機X5、ROGUE風扇車X2、飛輪X3
            還有很多配件跟輔助器材
          </p>
          <p>
            ⚠️rogue的器材目前因為美國疫情原因還是缺貨中，一有貨我們會盡快分批運回台灣，煩請大家見諒～
          </p>
          <p>
            另外我們也設有多功能草皮訓練區、雪橇、龍門架、農夫走路槓……就是希望滿足大家功能性訓練的需求，讓鍛鍊身體除了健康、好看，也要能運用在提升生活品質上。
          </p>
          <h3 style={{ color: '#fcce10' }}>🍴飲食休息區</h3>
          <p>座位舒適，讓渴望控制飲食卻又沒時間備餐的你，輕鬆獲得所需營養。</p>
          <h3 style={{ color: '#fcce10' }}>💦 淋浴間</h3>
          <p>
            男生淋浴間有三間，女生有兩間，在運動後舒舒服服沖個熱水澡，真的是最舒暢了！
          </p>
          <h3 style={{ color: '#fcce10' }}>💎 VIP教練課教室</h3>
          <p>
            對於健身初學者或是想更加精進自己的健客們，我們也設有VIP教室區；透明卻獨立的環境，帶給學生們的是最舒適與高雅的上課體驗。
          </p>
          <h3 style={{ color: '#fcce10' }}>
            👨‍⚕ Tanita 塔尼達 日本健康管理專家 MC-980MA體組成計
          </h3>
          <p>
            最後，我們最狂的器材來了，要價接近90萬的塔尼達體組成計，快速、準確、還能把報告印回家；就是要讓大家可以隨時監測自己的身體狀況與訓練成效，一步一步達成自己的理想體態！
          </p>
          <p>這麼優質的環境，你還在等什麼？</p>
          <h3 style={{ color: '#fcce10' }}>收費方式</h3>
          <span className="image left">
            {/* <img src={price} alt="" style={{ width: '100%', height: '60%' }} /> */}
          </span>
          <p>
            歡迎私訊、電話或來店諮詢報名一對一、一對二教練課或團體課程！
            <br /> 電話：02-8942-2056
            {/* <div
              class="fb-messengermessageus"
              messenger_app_id="3212226998840909"
              page_id="107493774239485"
              color="white"
              size="standard"
            ></div> */}
            <br />
            地址：
            <a
              href="https://goo.gl/maps/19bzzEvRA8mSyis4A"
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              新北市永和區民權路80號2樓
              <img src={googleMap} style={{ width: '24px' }} />
            </a>
          </p>
          <p>
            <iframe
              src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2F&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=3212226998840909"
              width="450"
              height="35"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allow="encrypted-media"
            ></iframe>
            <iframe
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2Fvideos%2F653402495384376%2F&show_text=0&width=560"
              width="560"
              height="315"
              style={{border:'none',overflow:'hidden'}}
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allowFullScreen="true"
            ></iframe>
          </p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3muscler/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/3musclers/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default Yonghe
